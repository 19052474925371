import { ChangeEvent, useContext, useState } from 'react';
import { useDebounce } from './use-debounce';
import { TICKET_PURCHASE_LIMIT } from '../utils/constants';
import { DashboardContext } from '../views';
import { classNames, handleinteractions } from '../helpers';

export const useNumberOfTickets = () => {
  const [numberOfTicketsLoading, setNumberOfTicketsLoading] = useState(false);
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [tempNumberOfTickets, setTempNumberOfTickets] = useState(1);

  const ticketMissmatch = tempNumberOfTickets !== numberOfTickets;

  const { paymentCode, handlePaymentCode, paymentIntent } = useContext(DashboardContext);

  const handleTempNumberOfTickets = (i: number) => {
    let val = i;

    if (val > TICKET_PURCHASE_LIMIT) {
      val = TICKET_PURCHASE_LIMIT;
    }
    setTempNumberOfTickets((prev) => prev + val);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeNumberOfTickets(Number(event.target.value));
  };

  const handleButtonPress = (i: number) => {
    changeNumberOfTickets(i);
  };

  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);
  const debouncedHandleButtonPress = useDebounce(handleButtonPress, 1000);

  const resetNumberOftickets = () => {
    setNumberOfTickets(1);
    setTempNumberOfTickets(1);
  };

  const changeNumberOfTickets = (i: number) => {
    let numberOftickets = i;
    if (numberOftickets <= 0) {
      setNumberOfTickets(1);
      setTempNumberOfTickets(1);
      return;
    }
    if (i > TICKET_PURCHASE_LIMIT) {
      numberOftickets = TICKET_PURCHASE_LIMIT;
    }
    setNumberOfTickets(numberOftickets);
    setTempNumberOfTickets(numberOftickets);
    setNumberOfTicketsLoading(true);
    handlePaymentCode(paymentCode, numberOftickets).finally(() => setNumberOfTicketsLoading(false));
  };

  const SelectTicketsComponent = () => (
    <div>
      <div className="p-4 text-center">
        <h5 className="text-xl font-medium text-black">
          {(paymentIntent?.paymentIntent.metadata as Record<string, string>)['facilityName']}
          <br />
          {(paymentIntent?.paymentIntent.amount ?? 0) / 100} {paymentIntent?.paymentIntent.currency}
        </h5>
        <p className="text-black">Antal biljetter {numberOfTickets}</p>
      </div>
      <div className="flex w-full flex-1 p-4 justify-center items-center">
        <button
          {...handleinteractions(() => {
            debouncedHandleButtonPress(tempNumberOfTickets - 1);
            handleTempNumberOfTickets(-1);
          })}
          className={classNames(
            'h-10 w-10 border border-primary-main text-primary-main bg-red-700 text-white rounded-md grid place-content-center text-2xl',
            tempNumberOfTickets <= 1 ? 'pointer-events-none opacity-30' : '',
          )}
        >
          &#45;
        </button>
        <div className="flex justify-center items-center w-full flex-1 min-w-[96px]">
          <input
            type="number"
            min={0}
            max={TICKET_PURCHASE_LIMIT}
            className="text-center text-2xl w-16 border-0 bg-transparent focus:ring-0"
            disabled={numberOfTicketsLoading}
            value={tempNumberOfTickets}
            onChange={(e) => {
              debouncedHandleInputChange(e as ChangeEvent<HTMLInputElement>);
              setTempNumberOfTickets(Number(e.target.value));
            }}
          />
        </div>
        <button
          {...handleinteractions(() => {
            debouncedHandleButtonPress(tempNumberOfTickets + 1);
            handleTempNumberOfTickets(+1);
          })}
          className={classNames(
            'h-10 w-10 border border-primary-main text-primary-main bg-green-700  text-white rounded-md grid place-content-center text-2xl',
            tempNumberOfTickets >= TICKET_PURCHASE_LIMIT ? 'pointer-events-none opacity-30' : '',
          )}
        >
          &#43;
        </button>
      </div>
    </div>
  );

  return {
    numberOfTickets,
    numberOfTicketsLoading,
    tempNumberOfTickets,
    ticketMissmatch,
    changeNumberOfTickets,
    debouncedHandleButtonPress,
    debouncedHandleInputChange,
    handleTempNumberOfTickets,
    resetNumberOftickets,
    SelectTicketsComponent,
    setNumberOfTickets,
    setTempNumberOfTickets,
  };
};
