import { useContext, useState } from 'react';
import { api } from '../api';
import { DashboardContext } from '../views';

export const useSwish = (numberOfTickets: number) => {
  const [swishLoading, setSwishLoading] = useState(false);

  const { paymentCode, setMessage, setPaymentCode } = useContext(DashboardContext);

  const clearPaymentCode = () => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      localStorage.removeItem('paymentCode');
    }
    setPaymentCode('');
  };

  const initSwish = async () => {
    setSwishLoading(true);
    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data } = await api.paymentsControllerInitSwishForFacility({
        facilityId: facility.id,
        initPaymentDto: { numberOfTickets },
      });

      clearPaymentCode();

      const appUrl = `swish://paymentrequest?token=${data.swishToken}&callbackurl=${window.location.origin}/swish/status?statusCheckToken=${data.statusCheckToken}`;
      window.location.href = appUrl;
    } catch (error) {
      setMessage('Något gick fel');
      setSwishLoading(false);
    }
  };

  return { initSwish, swishLoading };
};
